import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';
import { Helmet } from 'react-helmet';

import { COLOR_SURFACE_1 } from '../colors';

import { LandingCenter, LandingLayout } from '../layout';

/**
 *
 */
const Title = styled.h1`
  margin-top: 24px;
  line-height: 32px;
  font-size: 24px;
  font-weight: 400;
`;

/**
 *
 */
const SectionTitle = styled.h2`
  margin-top: 24px;
  padding-bottom: 8px;
  border-bottom: 1px solid ${COLOR_SURFACE_1};
  line-height: 24px;
  font-size: 20px;
  font-weight: 400;
`;

/**
 *
 */
const Question = styled.h6`
  margin-top: 16px;
  line-height: 24px;
  font-size: 20px;
  font-weight: 400;
`;

/**
 *
 */
const Answer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
  padding: 0px 16px 16px 16px;
  background-color: ${COLOR_SURFACE_1};
  border-radius: 4px;
  text-align: justify;
  font-weight: 400;

  p {
    margin-top: 16px;
  }
`;

/**
 *
 */
const StyledFaq = styled.div``;

export function Faq() {
  return (
    <LandingLayout>
      <StyledFaq>
        <Helmet>
          <title>FAQ | StackTrends</title>
          <meta name="description" content="" />
        </Helmet>

        <LandingCenter>
          <Title>Frequently Asked Questions</Title>

          <SectionTitle>General</SectionTitle>

          <Question>What is StackTrends?</Question>
          <Answer>
            <p>
              StackTrends is a website devoted to software engineering trend data. The data includes such metrics as how
              many developer jobs are available and what languages and frameworks are most/least in demand. All data can
              be viewed within the context of a location, whether that's by city, state, or nationwide.
            </p>
          </Answer>

          <SectionTitle>Services</SectionTitle>

          <Question>Do you have an API for the job listings?</Question>
          <Answer>
            <p>
              We do not currently offer an API into our data but feel free to <Link to="/about">reach out</Link> with
              inquiries.
            </p>
          </Answer>

          <SectionTitle>Data</SectionTitle>

          <Question>Where does the data come from?</Question>
          <Answer>
            <p>
              StackTrends' data is gathered from job sites each month and crunched into digestible metrics and models.
              We started crawling in November 2016 with only ~2,000 listings and now we gather over 140,000 unique,
              relevant listings per month.
            </p>
          </Answer>

          <Question>How reliable is your data? Some of the numbers seem a bit off.</Question>
          <Answer>
            <p>
              That's a pretty deep question but at the surface level, some parts of the data are 100% reliable and other
              parts are less. For example, because we are gathering more and more data each month, charts that display
              the "Number of Listings for Technology X" or the "Number of Jobs in City Y" will seem to indicate that
              Technology X went up in demand for the current period or that City Y has 2X more jobs. Charts like these
              will become more useful when we level off on the number of sites we analyze.
            </p>
            <p>
              Direct metrics like the "Number of Listings Analyzed" are very reliable though since they are never
              interpreted: they come straight from the crawlers. Derived metrics are less so due to needed tuning and
              refinement. For example, additional crawler tuning is needed to tell the difference between "Java" and
              "JavaScript" so that it's right 99.99% of the time. On the whole, we're glad to report that the data is
              very solid overall.
            </p>
            <p>
              For more pointed questions like, "But I know that React is more popular than Angular in San Francisco!",
              you might be right.
            </p>
            <p>
              The gist is that, holistically, StackTrends reflects what is most in demand by <em>employers</em>.
              Ultimately, StackTrends' data is drawn from job listings: what recruiters and hiring managers are seeking
              out, not what might be most popular among engineers, a location, etc. And we're OK with that since many
              devs will use StackTrends to feel out the job market in different cities and states.
            </p>
          </Answer>

          <Question>Does the data include jobs not posted to job search sites?</Question>
          <Answer>
            <p>No.</p>
            <p>
              Some people have noted that the metrics aren't as accurate as they could be because we don't take into
              account <em>all</em> job listings. After all, listings that are never posted to job sites (which is often
              the case in hot markets like San Francisco), or listings that are on sites that require a login (like
              Hired), or listings on sites that are particularly litigious (looking at you LinkedIn!) are not included.
              So to their point, those people are correct.
            </p>
            <p>Unfortunately, there isn't a reliable way (yet?!) to get our hands on this data every month.</p>
          </Answer>

          <Question>Why don't you have analytics for my city? Country?</Question>
          <Answer>
            <p>Regarding cities:</p>
            <p>
              When StackTrends first launched, we chose the biggest and/or most well-known 25 cities to gather data on.
              Cities with few listings don't tend to bring a lot of value to the site and can distort some metrics. If
              you think I missed an important one, please <Link to="/about">let us know!</Link>
            </p>
            <p>Regarding countries:</p>
            <p>
              For now -- and the foreseeable future -- StackTrends will only be focusing on U.S. data. Sorry about that.
              :(
            </p>
          </Answer>

          <Question>It would be really cool to see data/charts for XYZ!</Question>
          <Answer>
            <p>
              <Link to="/about">We'd love to hear your ideas!</Link>
            </p>
          </Answer>

          <Question id="why_job_listings_have_multiple_technology">
            Why can job listings contain more than one technology?
          </Question>
          <Answer>
            <p>
              Unlike most other industries, tech job listings rarely contain only one specialization. Though it wouldn't
              make sense for a hospital to post a job opening for a neuro surgeon or a dermatologist, the analog happens
              all the time in tech.
            </p>
            <p>
              As you would expect, tech jobs require proficiency in more than one technology. Even at large companies
              with hundreds or thousands of highly-specialized engineers the expectation is to be familiar with some or
              all of the unique mix of technologies used within the organization or team. This mix is called the tech
              "stack". Job listings reflect this need and feature several technologies within the target stack. But
              there are other factors at play as well.
            </p>
            <p>
              There are hundreds of technologies active in the marketplace and many have significant overlap in the
              problems they are trying to solve. And because technologists are at high demand in general, recruiters
              must cast a wider net in order to attract interest and not disqualify applicants. Sometimes this is ok
              because a technologist competant in one specialty might be able to easily turn their hand to another or
              may be looking to make that move themseleves. But many times it can lead to wasted time. For many senior
              or lead positions, the hiring company actually needs a C# expert, not "someone with OOP experience."
              Likewise, a company may be searching for a Vue specialist to lead a team to refactor their entire site,
              not a candidate with "Angular, React, or jQuery background."
            </p>
            <p>
              Lastly, if a recruiter or hiring manager is having a difficult time filling a position due to location,
              salary requirements, recruiter competition, technology demand, or negative company reputation they will
              sometimes resort to "keyword stuffing" to encourage the listing to appear in as many result verticals as
              possible. StackTrends regularly scans listings with over 30 unique technologies in a single listing; the
              current record is 47.
            </p>
            <p>
              The good news is that this reality allows us to spot patterns regarding what technologies are related or
              compete.
            </p>
          </Answer>
        </LandingCenter>
      </StyledFaq>
    </LandingLayout>
  );
}

export default Faq;
